import React, { useContext, useState } from "react"
import { FormattedMessage } from "react-intl"
import { ContestantsContext } from "../../../App"
import ContestantCard from "../../Partials/ContestantCard"

export default function SectionEvent() {
  const { contestants, favorites } = useContext(ContestantsContext)

  const [tab, setTab] = useState("0")

  return (
    <section className="section-event">
      <div className="bs-container">
        <div className="bs-row">
          <div className="bs-col">
            <div className="module module-event">
              <div className="module-header" data-aos="fade-up">
                <span className="id">03</span>
                <h2 className="title">
                  <FormattedMessage id="index.contestants" />
                </h2>
              </div>

              <div className="module-content">
                <div
                  className="tab-control"
                  style={{
                    textAlign: "center",
                    paddingBottom: 60,
                  }}
                >
                  <button
                    className="control__btn active"
                    type="button"
                    data-show="#tab1"
                    onClick={() => {
                      setTab("0")
                    }}
                  >
                    Tất cả
                  </button>
                  <button
                    className="control__btn"
                    type="button"
                    data-show="#tab2"
                    onClick={() => {
                      setTab("1")
                    }}
                  >
                    MISS NEU
                  </button>
                  <button
                    className="control__btn"
                    type="button"
                    data-show="#tab3"
                    onClick={() => {
                      setTab("2")
                    }}
                  >
                    MR NEU
                  </button>
                </div>
                <div className="tab-content">
                  <div className="tab-item active">
                    <div className="bs-row row-xs-15">
                      {contestants.length ? (
                        contestants.map((el) => {
                          // const favoritePoint =
                          //   favorites.find(
                          //     (f) => f.contestantId === el.contestantId
                          //   )?.totalVote || 0

                          if (tab === "0") {
                            return (
                              <ContestantCard
                                key={el._id}
                                code={el.code}
                                missName={el.fullname}
                                votePoints={el.totalPoint}
                                missId={el.contestantId}
                                favoritePoint={0}
                              />
                            )
                          } else {
                            if (el.info.table === tab) {
                              return (
                                <ContestantCard
                                  key={el._id}
                                  code={el.code}
                                  missName={el.fullname}
                                  votePoints={el.totalPoint}
                                  missId={el.contestantId}
                                  favoritePoint={0}
                                />
                              )
                            }
                          }
                        })
                      ) : (
                        <div
                          className="bs-col lg-25-15 md-33-15 xs-50-15"
                          style={{
                            margin: "auto",
                            textAlign: "center",
                          }}
                        >
                          Không có
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
