import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { ContestantsContext } from "../../../App"

import LayoutRankPage from "../../Layouts/LayoutRankPage"
import RankItem from "./RankItem"
import { useParams } from "react-router-dom"

export default function Rank(props) {
  const { table } = useParams()

  // const title =
  //   props.lang === 'en'
  //     ? 'Rank - Poinsettia Festival English'
  //     : 'Bảng xếp hạng - NEU GLAMOUR 2023';

  const { ranks, favorites } = useContext(ContestantsContext)

  const tableRank = ranks.filter((e) => {
    return e.info.table === table
  })

  return (
    <LayoutRankPage>
      <>
        <section className="section-result">
          <div className="header-nav">
            <div className="bs-container">
              <div className="module-header clearfix">
                <div
                  className="logo aos-init aos-animate"
                  data-aos="fade-right"
                >
                  <Link to="/">
                    {/* <img alt="img" src="images/logo.gif" /> */}
                    <img alt="logo" src="/images/logo-header.png" />
                  </Link>
                </div>

                <div
                  className="kols"
                  // style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* <Link className="link" to="/">
                    Trang chủ
                  </Link> */}

                  <Link
                    className="link"
                    to={`/bang-xep-hang/${table === "1" ? "2" : "1"}`}
                  >
                    {table === "1" ? "MISS NEU ➜" : "MR NEU ➜"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bs-container">
            <div className="bs-row">
              <div className="bs-col">
                <div className="module module-result">
                  {/* <ul className="tab-module">
                    <li
                      onClick={() => setTab("voteScore")}
                      className={isVoteTab ? "active" : ""}
                    >
                      Xếp hạng điểm bình chọn
                    </li>
                    <li
                      onClick={() => setTab("voteFavourite")}
                      className={!isVoteTab ? "active" : ""}
                    >
                      Xếp hạng điểm yêu thích
                    </li>
                  </ul> */}

                  <div className="module-content">
                    <div className="wrapper-content">
                      {/* //! top 1 */}
                      <div className="bs-row row-sm-5 row-xs-10 row-md-15">
                        <div className="bs-col xs-100-5 sm-100-10 md-100-15 lg-100-15">
                          <div
                            className="item clearfix aos-init aos-animate"
                            data-aos="fade-up"
                          >
                            <RankItem
                              rank={1}
                              info={tableRank[0] || {}}
                              point={tableRank[0]?.totalPoint}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="top-2">
                        {/* //! top 2 */}
                        <div className="bs-row row-sm-5 row-xs-10 row-md-15">
                          <div className="bs-col xs-100-5 sm-50-10 md-50-15 lg-50-15">
                            <div
                              className="item-top-2 item clearfix aos-init aos-animate"
                              data-aos="fade-up"
                            >
                              <RankItem
                                rank={2}
                                info={tableRank[1] || {}}
                                point={tableRank[1]?.totalPoint}
                              />
                            </div>
                          </div>
                          <div className="bs-col sx-100-5 sm-50-10 md-50-15 lg-50-15">
                            <div
                              className="item-top-2 item clearfix aos-init aos-animate"
                              data-aos="fade-up"
                            >
                              <RankItem
                                rank={3}
                                info={tableRank[2] || {}}
                                point={tableRank[2]?.totalPoint}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* //! top 3 */}
                      <div className="bs-row row-sm-5 row-xs-10 row-md-15">
                        {tableRank?.map((item, index) => {
                          if (index >= 3)
                            return (
                              <div
                                key={item?.data?.id}
                                className="bs-col xs-50-5 sm-33-10 md-25-15 lg-25-15"
                              >
                                <div
                                  className="item-top-4 item clearfix aos-init aos-animate"
                                  data-aos="fade-up"
                                >
                                  <RankItem
                                    rank={index + 1}
                                    info={item || {}}
                                    point={item?.totalPoint}
                                  />
                                </div>
                              </div>
                            )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </LayoutRankPage>
  )
}
