import $ from "jquery"
import React, { Component } from "react"
import { injectIntl } from "react-intl"
import BaseLayout from "../../Layouts/BaseLayout"
import SectionAbout from "./SectionAbout"
import SectionActivity from "./SectionActivity"
import SectionBanner from "./SectionBanner"
import SectionBrand from "./SectionBrand"
import SectionEvent from "./SectionEvent"

class Index extends Component {
  async componentDidMount() {
    $(".tab-control")
      .find(".control__btn")
      .click(function () {
        $(this).addClass("active")
        $(this).siblings().removeClass("active")
        $($(this).attr("data-show")).slideDown()
        $($(this).attr("data-show")).siblings().slideUp()
        $(".ImagesFrameCrop0").each(function () {
          $(this).removeClass("wide")
          $(this).removeClass("tall")
          if (
            $(this).width() / $(this).height() >
            $(this).parent().width() / $(this).parent().height()
          ) {
            $(this).addClass("wide")
          } else {
            $(this).addClass("tall")
          }
        })

        $(".ImagesFrameCrop0")
          .children("img")
          .each(function () {
            $(this).removeClass("wide")
            $(this).removeClass("tall")
            if (
              $(this).width() / $(this).height() >
              $(this).parent().width() / $(this).parent().height()
            ) {
              $(this).addClass("wide")
            } else {
              $(this).addClass("tall")
            }
          })
      })
  }

  render() {
    const title =
      this.props.lang === "en"
        ? "Home - Poinsettia Festival English"
        : "Trang chủ - NEU GLAMOUR 2023"

    return (
      <BaseLayout
        title={title}
        path="/"
        description={title}
        setLang={this.props.setLang}
      >
        <main id="main">
          <SectionBanner />
          <SectionAbout lang={this.props.lang} />
          <SectionActivity />
          <SectionEvent />
          <SectionBrand />
        </main>
      </BaseLayout>
    )
  }
}

export default injectIntl(Index)
