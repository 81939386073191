import { useCallback, useState } from "react"
import { request } from "./api/axios"
import API from "./api/url.js"
import { IMAGE_HOST } from "../config/constants"

export const useActivity = () => {
  const [data, setData] = useState([])
  const getData = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.ACTIVITY,
    })
    setData(res?.data?.voteList || [])
  }, [])
  return { data, getData }
}

export const useContestant = () => {
  const [contestants, setContestant] = useState([])
  const [ranks, setRanks] = useState([])
  const [favorites, setFavorites] = useState([])

  const getContestant = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.CONTESTANT,
    })
    setContestant(res?.data?.listAll || [])
  }, [])

  const getRanks = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.RANK,
    })
    setRanks(res?.data?.rank || [])
  }, [])

  const getFavorite = useCallback(async () => {
    const res = await request({
      method: "GET",
      url: API.FAVORITE,
    })
    setFavorites(res?.data?.rank || [])
  }, [])

  return {
    contestants,
    ranks,
    favorites,
    getRanks,
    getContestant,
    getFavorite,
  }
}

export const useVoteList = () => {
  const [voteList, setVoteList] = useState([])
  const [isHasMore, setHasMore] = useState(true)
  const getVoteList = useCallback(
    async (missId, pageNumber, pageSize) => {
      if (isHasMore) {
        const res = await request({
          method: "GET",
          url: API.VOTE_LIST(missId, pageNumber, pageSize),
        })
        const temp = res?.data?.missVoteList || []
        if (temp.length === 0) setHasMore(false)
        setVoteList((prev) => prev.concat(temp))
      }
    },
    [isHasMore]
  )
  return { voteList, isHasMore, getVoteList }
}

export const useContestantDetail = () => {
  const [contestant, setContestant] = useState({})
  const [time, setTime] = useState({})
  const getContestantDetail = useCallback(async (missId) => {
    const res = await request({
      method: "GET",
      url: API.CONTESTANT_DETAIL(missId),
    })
    setContestant(res?.data?.missDetail || {})
    setTime(res.data?.time || {})
  }, [])

  const getPayUrl = useCallback(async (gateway, missId, combo) => {
    const res = await request({
      method: "GET",
      url: API.PAY_URL(gateway, missId, combo),
    })
    return res
  }, [])

  return { time, contestant, getContestantDetail, getPayUrl }
}

export const configId = (missId) => {
  const codeIndex = {
    1: 19,
    2: 16,
    3: 39,
    4: 29,
    5: 4,
    6: 17,
    7: 38,
    8: 21,
    9: 2,
    10: 9,
    11: 28,
    12: 33,
    13: 13,
    14: 5,
    15: 20,
    16: 1,
    17: 6,
    18: 7,
    19: 22,
    20: 25,
    21: 14,
    22: 35,
    23: 34,
    24: 12,
    25: 23,
    26: 36,
    27: 26,
    28: 18,
    29: 37,
    30: 27,
    31: 10,
    32: 3,
    33: 8,
    34: 24,
    35: 11,
    36: 31,
    37: 40,
    38: 32,
    39: 30,
    40: 15,
  }
  return getKeyByValue(codeIndex, missId)
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] == value)
}

export const getAvatarUrl = (missId) => {
  return `${IMAGE_HOST}/neu2023/AVA/${missId}.jpg`
}

export const getDetailImage = (missId) => {
  return `${IMAGE_HOST}/neu2023/Detail/${missId}.jpg`
}
