export const INTRO_HOME = `
<div class="reward-content">
  <div class="reward-part">
  <h4 style="text-align:left">
  NEU GLAMOUR 2023 - 4 YEARS FOR YOU
  </h4>
    <p style="text-align:left">
    Trở lại sau 4 năm, với chủ đề Younique, cuộc thi năm nay mong muốn tạo nên một sân khấu để các sinh viên thỏa sức tỏa sáng với những nét cá tính riêng của bản thân. Trở thành một phần của cộng đồng nhưng vẫn có tiếng nói cá nhân, có cá tính riêng nhưng vẫn mang màu sắc của cả cộng đồng, đó chính là điều mà cuộc thi năm nay tìm kiếm và kỳ vọng từ các bạn sinh viên.
    Từ hàng trăm hồ sơ đăng ký, Ban tổ chức đã chọn ra 100 thí sinh tiêu biểu bước vào Vòng Sơ khảo (14/5). Top 40 thí sinh xuất sắc nhất tại Vòng Sơ khảo bước tiếp vào các vòng thi phụ đầy gay cấn: Vòng thi Ảnh (28/5 - 6/6); Vòng thi Ý tưởng Kinh tế (28/5); Vòng thi Tài năng (3/6) và Vòng thi Catwalk (7/6).
    </p>
    </div>
  </div>
</div>
`

export const INTRO_TITLE = `Là cổng bình chọn chính thức của cuộc thi Sinh viên Thanh lịch trường Đại học Kinh tế Quốc dân - NEU Glamour 2023, Bvote tự hào mang đến giải pháp bình chọn trực tuyến công bằng, minh bạch cho các thí sinh.`

export const INTRO = `
  <div class="reward-content">
  <div class="reward-part">
<div class="item">
<p>
<span style="font-size:16px; font-family: roboto; ">
<span style="color:#000000">
<span style="font-family:roboto">
Cổng bình chọn Bvote sẽ đồng hành cùng cuộc thi để tìm kiếm ra những thí sinh được yêu thích nhất qua sự bình chọn của khán giả. 
  </span>
  </span>
  </span>
  </p>
   <p>
  <span style="font-size:16px"><span style="color:#000000">
  <span style="font-family:roboto">
  Việc hoạt động dựa trên công nghệ chuỗi khối của Blockchain giúp giải pháp bình chọn của Bvote sở hữu những ưu điểm vượt trội về tính an toàn, bảo mật, đem lại kết quả công khai và minh bạch nhất. Thay vì sử dụng phương thức truyền thống bằng tin nhắn, khán giả sẽ ủng hộ cho thí sinh mình yêu thích nhất thông qua hệ thống bình chọn trên Website. Giải pháp này cho phép mọi người theo dõi kết quả liên tục, trực tiếp trên hệ thống, một điều mà các hình thức cũ không làm được. Không chỉ loại bỏ nguy cơ gian lận, Bvote còn mang đến trải nghiệm bình chọn mới lạ, tiện lợi và dễ sử dụng. Nhất là khi giải pháp bình chọn của Bvote còn kết hợp cùng các ví điện tử uy tín để đảm bảo mức độ bảo mật cao và giảm thiểu tối đa tình trạng hack vote.
  <br/>
  <br/>
  </span>
    </span>
      </p> 
      <p>
      &nbsp;
      </p> 
      <p>
      <span style="font-size:16px">
      <span style="color:#000000">
      <span style="font-family:roboto">
      <strong>
      Cách thức bình chọn:
      </strong>
      <br/>
      <br/>
      <strong>
      Ban tổ chức đưa lên thông tin thí sinh để khán giả bình chọn
      </strong>
      </span>
      </span>
      <span style="color:#000000">
      <span style="font-family:roboto"> 
      </span>
      </span>
      </span>
      </p>
       <p>
       <span style="font-size:16px">
       <span style="color:#000000">
       <span style="font-family:roboto">
       - <i>Bước 1: &nbsp;</i> Truy cập website: https://neuglamour2023.bvote.vn/. Tại phần Danh sách thí sinh, click chi tiết vào thí sinh muốn bình chọn
       </span>
       </span>
       </span>
       </p> 
       <p>
       <span style="font-size:16px">
       <span style="color:#000000">
       <span style="font-family:roboto">
       - <i>Bước 2: &nbsp;</i> Click Bình chọn Momo hoặc Bình chọn qua Ngân hàng, thẻ. Hệ thống sẽ trả về 1 link thanh toán để bình chọn thí sinh.
       </span>
       </span>
       </span>
       </p>
        <p><span style="font-size:16px">
        <span style="color:#000000">
        <span style="font-family:roboto">
        - <i>Bước 3: &nbsp;</i> Truy cập link thanh toán, tiến hành thanh toán (tương đương bình chọn). 

        </span>
        </span>
        </span>
        </p>
         <p>
         <span style="font-size:16px">
         <span style="color:#000000">
         <span style="font-family:roboto">
         - <i>Bước 4: &nbsp;</i> Hệ thống check thanh toán thành công, hệ thống Bvote sẽ tiến hành lưu trữ kết quả bình chọn. Phí bình chọn theo gói lựa chọn và 1 lượt bình chọn qua Momo tương đương với số điểm theo gói bình chọn mà khán giả đã chọn. Lưu ý sẽ không giới hạn số lượt bình chọn và số thí sinh bình chọn.
 
         </span>
         </span>
         </span>
         </p> 
   
         <br/>
          <p>
          &nbsp;
          </p> 
          <p>
          <span style="font-size:16px">
          <strong>
          <span style="color:#000000">
          <span style="font-family:roboto">
          Cơ cấu giải thưởng: 
          </span>
          </span>
          </strong>
          </span>
          </p> 
          <p>
          <span style="font-size:16px">
          <span style="color:#000000">
          <span style="font-family:roboto">
          Thí sinh có lượt bình chọn cao nhất trên hệ thống bình chọn Bvote sẽ nhận giải "Thí sinh được yêu thích nhất" và lọt thằng top 20 của cuộc thi sinh viên thanh lịch trường ĐH Kinh tế Quốc dân 2023.
          </p> 

           <p>
          <span style="font-size:16px">
          <span style="color:#000000">
          <span style="font-family:roboto">
          Cổng bình chọn của Bvote sẽ chính thức được mở vào 19h00 ngày 29/05. và sẽ kết thúc vào 23h59 ngày 06/06. để chọn ra 2 thí sinh có điểm cao nhất lọt thẳng top 20 đêm chung kết.
          </p> 

           <p>
          <span style="font-size:16px">
          <span style="color:#000000">
          <span style="font-family:roboto">
          Kết quả thí sinh được yêu thích nhất sẽ được BTC trao giải thưởng trong đêm Gala.
          </p>  
</div>
</div>
</div>
  `
